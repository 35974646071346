import { useParams } from "react-router-dom";
import VideoCall from "../components/VideoCall";

const MeetingPage = () => {
  const { id } = useParams();
  const roomId = `vpaas-magic-cookie-9fb9da15f46d4f48b77fcb7a007ce612/${id}`;
  const user = {
    name: "Promoteur Immo",
    email: "support@fi-dari.tn",
    avatar: "https://fi-dari.tn/img/fidari-immobilier.jpg",
  };

  const handleCopyLink = () => {
    const meetingUrl = `${window.location.origin}/meeting/${id}`;
    navigator.clipboard.writeText(meetingUrl);
    alert("Meeting link copied!");
  };

  return (
    <div>
      <h2>Meeting Room {id}</h2>
      <button onClick={handleCopyLink}>Copy Meeting Link</button>
      <VideoCall roomId={roomId} user={user} />
    </div>
  );
};

export default MeetingPage;
