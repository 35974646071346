import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import JITSI_CONFIG from "../jitsiConfig";

const VideoCall = ({ roomId, user }) => {
  const jitsiContainerRef = useRef(null);
  const [jwtToken, setJwtToken] = useState("");
  console.log("test VideoCall");
  useEffect(() => {
    // Fetch JWT token from backend
    
    axios
      .post("http://localhost:5000/api/jitsi/generateToken", { roomName: roomId, user })
      .then((res) => setJwtToken(res.data.token))
      .catch((err) => console.error("JWT Error:", err));
  }, [roomId, user]);

  useEffect(() => {
    console.log("roomId", roomId);
    console.log("jwtToken", jwtToken);
    if (window.JitsiMeetExternalAPI && jwtToken) {
      const api = new window.JitsiMeetExternalAPI(JITSI_CONFIG.domain, {
        ...JITSI_CONFIG.options,
        roomName: roomId,
        parentNode: jitsiContainerRef.current,
        jwt: jwtToken, // Use JWT token
      });

      return () => api.dispose();
    }
    
  }, [roomId, jwtToken]);

  return <div ref={jitsiContainerRef} style={{ width: "100%", height: "500px" }} />;
};

export default VideoCall;
