const JITSI_CONFIG = {
    domain: "8x8.vc", // Jitsi as a Service (JaaS)
    options: {
      roomName: "*",
      width: "100%",
      height: "500px",
      jwt: "", // JWT token will be set dynamically
      parentNode: null,
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        SHOW_JITSI_WATERMARK: false,
      },
      configOverwrite: {
        disableDeepLinking: true,
      },
    },
  };
  
  export default JITSI_CONFIG;
  